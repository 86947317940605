import React, { useState, useEffect } from "react"
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "./contexts/authContext";
import { useTranslation } from 'react-i18next';
import './login.css'
const Login = () => {
    const [loading, setLoading] = useState(false)
    const [loadingResend, setLoadingResend] = useState(false)
    const [makeOTPVisible, setMakeOTPVisible] = useState(false);
    const [phoneNumberTaken, setPhoneNumberTaken] = useState(false);
    const { setFirstName, setLastName, setToken, setPhoneNumber, setUserID, setShippingAddress } = useAuth()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        phoneNumber: "",
    });

    const [formOTPData, setFormOTPData] = useState({
        otp: "123",
    });

    const [errors, setErrors] = useState({});

    const validationSchema = Yup.object({
        phoneNumber: Yup.string()
            .matches(/^(9|7)\d{8}$/, t('phone_number_invalid_regex'))
            .required(t('phone_number_required')),
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await validationSchema.validate(formData, { abortEarly: false });
            try {
                const response = await fetch(`${process.env.REACT_APP_API}/verify_mobile`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ phonenumber: formData.phoneNumber }),
                });

                const responseStatus = await fetch(`${process.env.REACT_APP_API}/verify_status`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ phonenumber: formData.phoneNumber }),
                });

                const data = await response.json();

                if (data.code === 409 && responseStatus.status === 200) { // Assuming API returns a field `exists` to indicate phone number presence
                    setPhoneNumberTaken(true);
                    setErrors({})
                    //Signup customer
                    try {
                        const data_customer = {
                            phonenumber: formData.phoneNumber,
                            otp: formOTPData.otp
                        }
                        const response = await fetch(`${process.env.REACT_APP_API}/mobile_signin`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(data_customer),
                        });

                        const data = await response.json();

                        if (data.code === 404) { // Assuming API returns a field `exists` to indicate phone number presence                  
                            setErrors(prevErrors => ({
                                ...prevErrors,
                                otp: t('otp_invalid'),
                            }));
                        } else {
                            setErrors({})

                            navigate('/')
                            setToken(data.token)
                            setUserID(data.user.id)
                            setPhoneNumber(data.user.phonenumber)
                            setShippingAddress(data.user.address)
                            setFirstName(data.user.firstname)
                            setLastName(data.user.lastname)
                        }
                    } catch (apiError) {
                        // Optionally, you can set a generic error message here
                        setErrors(prevErrors => ({
                            ...prevErrors,
                            otp: t('general_error'),
                        }));
                    } finally {
                        setLoading(false)
                    }

                } else if (responseStatus.status === 409) {
                    setPhoneNumberTaken(false);
                    setErrors(prevErrors => ({
                        ...prevErrors,
                        phoneNumber: t('account_inactive'),
                    }));
                } else {
                    setPhoneNumberTaken(false);
                    setErrors(prevErrors => ({
                        ...prevErrors,
                        phoneNumber: t('not_registered_phone_number'),
                    }));

                    navigate('/registration');
                }
            } catch (error) {
                console.log('Inside catch block of verify_mobile: ', error);
            }
        } catch (error) {
            const newErrors = {};
            error.inner.forEach((err) => {
                newErrors[err.path] = err.message;
            });

            setErrors(newErrors);
        } finally {
            setLoading(false);
        }
    };

    const handleChange = async (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <>
            <div className="container dark:bg-drkmode">
                <div className="grid col-span-1 md:grid-cols-3">
                    <div className="first"></div>
                    <div className="second">
                        <div className="header">
                            <p className="text-center text-xl font-bold mt-8 dark:text-gray-200">{t('sign_in')}</p>
                        </div>
                        <form className="m-5 p-5" onSubmit={handleSubmit} method="post">
                            <div className="group-three mt-3">
                                <label htmlFor="" className="dark:text-gray-200">{t('mobile_number')}</label>
                                <input className="border border-gray-300 rounded p-2 w-full" onChange={handleChange} name="phoneNumber" type="text" value={formData.phoneNumber} placeholder={t('mobile_number')} />
                                {errors.phoneNumber && (
                                    <div className="error">{errors.phoneNumber}</div>
                                )}
                            </div>
                            <div className="group-five">
                                {
                                    loading
                                        ?
                                        (
                                            <button disabled={loading} className="p-2 text-white rounded w-full mt-5 bg-brand dark:bg-drkmodebrand" type="submit">{t('sending_otp')} ......</button>
                                        )
                                        : (
                                            <button className="p-2 text-white rounded w-full mt-5 bg-brand dark:bg-drkmodebrand" type="submit">{t('next')}</button>
                                        )
                                }

                            </div>
                            <div className="mt-5 text-center">
                                <a href="/registration" className="p-2 rounded w-full text-sm text-brand dark:text-drkmodebrand">{t('no_account')}</a>
                            </div>
                        </form>
                    </div>
                    <div className="third"></div>
                </div>
            </div>
        </>
    )
}

export default Login