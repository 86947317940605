import React, { useEffect, useState } from 'react';
import { useOrder } from './contexts/orderContext';
import './order.css';
import { useTranslation } from 'react-i18next';
const OrderPage = () => {
    const [activeTab, setActiveTab] = useState('order');
    const { order, history, status, cancelOrderItem, cancelOrder, setForceUpdate, forceUpdate } = useOrder();
    const { t, i18n } = useTranslation();
    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const totalPriceAfterDiscount = (total, discount) => {
        const totalPrice = total - (total * (discount / 100));
        return totalPrice;
    }

    const handleItemCancellation = async (itemToCancel) => {
        await cancelOrderItem(itemToCancel);
    };

    const addCommasToNumber = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const renderOrders = (orders) => {
        if (!orders || orders.length === 0) {
            return <p className='dark:text-gray-200'>{t('no_current_orders')}.</p>;
        }

        return orders.map((order) => {
            const totalPrice = order.items.reduce((acc, item) => {
                const itemTotal = item.price * item.qty;

                // Apply item discount first, then additional discount
                const priceAfterItemDiscount = item.discount > 0
                    ? totalPriceAfterDiscount(itemTotal, item.discount)
                    : itemTotal;

                const finalPrice = item.additional_discount > 0
                    ? totalPriceAfterDiscount(priceAfterItemDiscount, item.additional_discount)
                    : priceAfterItemDiscount;

                return acc + finalPrice;
            }, 0);

            return (
                <div key={order.id} className="order-item">
                    <div className="order-details dark:bg-drkmode dark:text-gray-200">
                        <h3 className='font-bold'>{order.name}</h3>
                        <p className='font-bold'>{t('total_price')}:
                            <span style={{ marginLeft: '0px' }} className='dark:text-drkmodebrand text-brand'>
                                {t('etb')} {addCommasToNumber(totalPrice.toFixed(2))}
                            </span>
                        </p>
                        {order.items.map((item, index) => (
                            <div key={index} className="item-details">
                                <img src={`${process.env.REACT_APP_API}/${item.photos[0]}`} alt={item.name} className="order-image" />
                                <div className="item-info">
                                    <h4>{item.name}</h4>
                                    <p>{item.description}</p>
                                    {item.discount > 0 || item.additional_discount > 0 ? (
                                        <>
                                            <p style={{ color: 'red', textDecoration: 'line-through' }}>
                                                {t('price')}: {addCommasToNumber(item.price * item.qty)}
                                            </p>
                                            <p className='dark:text-drkmodebrand text-brand'>
                                                {t('price')}: {addCommasToNumber(
                                                    totalPriceAfterDiscount(
                                                        totalPriceAfterDiscount(item.price * item.qty, item.discount),
                                                        item.additional_discount
                                                    ).toFixed(2)
                                                )}
                                            </p>
                                        </>
                                    ) : (
                                        <p className='dark:text-drkmodebrand text-brand'>
                                            {t('price')}: {addCommasToNumber(item.price * item.qty)}
                                        </p>
                                    )}
                                    <p>{t('quantity')}: {item.qty}</p>
                                    <p>{t('order_status')}:<span className='font-bold' style={{ marginLeft: '0px', color: '#fed700' }}> {item.status}</span></p>
                                    {(order.status === 'Pending' || order.status === 'Order Accepted') && (
                                        <button onClick={() => handleItemCancellation(item)}>{t('cancel_item')}</button>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                    {(order.status === 'Pending' || order.status === 'Order Accepted') && (
                        <div className="flex justify-end">
                            <button onClick={() => handleItemCancellation(order)}>{t('cancel_all_order')}</button>
                        </div>
                    )}
                </div>
            );
        });
    };

    const renderHistoryOrders = (orders) => {
        if (!orders || orders.length === 0) {
            return <p className='dark:text-gray-200'>{t('no_current_history')}.</p>;
        }

        return orders.map((order) => {
            // Calculate total price by applying each discount one at a time (compounding discounts)
            const totalPrice = order.items.reduce((acc, item) => {
                const itemTotal = item.price * item.qty;

                // Apply item discount first, then additional discount if available
                const priceAfterItemDiscount = item.discount > 0
                    ? totalPriceAfterDiscount(itemTotal, item.discount)
                    : itemTotal;

                const finalPrice = item.additional_discount > 0
                    ? totalPriceAfterDiscount(priceAfterItemDiscount, item.additional_discount)
                    : priceAfterItemDiscount;

                return acc + finalPrice;
            }, 0);

            // Calculate total paid price for items with status 'Completed'
            const totalPaidPrice = order.items.reduce((acc, item) => {
                if (item.status === 'Completed') {
                    const itemTotal = item.price * item.qty;

                    // Apply item discount first, then additional discount if available
                    const priceAfterItemDiscount = item.discount > 0
                        ? totalPriceAfterDiscount(itemTotal, item.discount)
                        : itemTotal;

                    const finalPrice = item.additional_discount > 0
                        ? totalPriceAfterDiscount(priceAfterItemDiscount, item.additional_discount)
                        : priceAfterItemDiscount;

                    return acc + finalPrice;
                }
                return acc; // If the item status is not 'Completed', return the accumulator unchanged
            }, 0);

            return (
                <div key={order.id} className="order-item">
                    <div className="order-details dark:bg-drkmode dark:text-gray-200">
                        <h3 className='font-bold'>{order.name}</h3>
                        <p className='font-bold'>
                            {t('total_price')}: <span className='dark:text-drkmodebrand text-brand' style={{ marginLeft: '0px' }}>
                                {t('etb')} {addCommasToNumber(totalPrice.toFixed(2))}
                            </span>
                        </p>
                        <p className='font-bold'>
                            {t('total_paid_price')}: <span className='dark:text-drkmodebrand text-brand' style={{ marginLeft: '0px' }}>
                                {t('etb')} {addCommasToNumber(totalPaidPrice.toFixed(2))}
                            </span>
                        </p>
                        {order.items.map((item, index) => (
                            <div key={index} className="item-details">
                                <img src={`${process.env.REACT_APP_API}/${item.photos[0]}`} alt={item.name} className="order-image" />
                                <div className="item-info">
                                    <h4>{item.name}</h4>
                                    <p>{item.description}</p>
                                    {item.discount > 0 || item.additional_discount > 0 ? (
                                        <>
                                            <p style={{ color: 'red', textDecoration: 'line-through' }}>
                                                {t('price')}: {addCommasToNumber(item.price * item.qty)}
                                            </p>
                                            <p className='dark:text-drkmodebrand text-brand'>
                                                {t('price')}: {addCommasToNumber(
                                                    totalPriceAfterDiscount(
                                                        totalPriceAfterDiscount(item.price * item.qty, item.discount),
                                                        item.additional_discount
                                                    ).toFixed(2)
                                                )}
                                            </p>
                                        </>
                                    ) : (
                                        <p className='dark:text-drkmodebrand text-brand'>
                                            {t('price')}: {addCommasToNumber(item.price * item.qty)}
                                        </p>
                                    )}
                                    <p>{t('quantity')}: {item.qty}</p>
                                    {item.status === 'Canceled' ? (
                                        <p>{t('order_status')}:<span className='font-bold' style={{ marginLeft: '0px', color: '#DC3545' }}> {item.status}</span></p>
                                    ) : (
                                        <p>{t('order_status')}:<span className='font-bold dark:text-drkmodebrand text-brand' style={{ marginLeft: '0px' }}> {item.status}</span></p>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            );
        });
    };

    useEffect(() => {
        setForceUpdate(!forceUpdate);
    }, []);

    return (
        <div className="order-page dark:bg-drkmode">
            <div className="tabs">
                <button
                    className={`tab-button ${activeTab === 'order' ? 'active dark:bg-drkmodebrand text-gray-200 bg-brand' : ''}`}
                    onClick={() => handleTabChange('order')}
                >
                    {t('order')}
                </button>
                <button
                    className={`tab-button ${activeTab === 'history' ? 'active dark:bg-drkmodebrand text-gray-200 bg-brand' : ''}`}
                    onClick={() => handleTabChange('history')}
                >
                    {t('history')}
                </button>
            </div>
            <div className="tab-content">
                {activeTab === 'order' && (
                    <div className="order-list">
                        {renderOrders(order)}
                    </div>
                )}
                {activeTab === 'history' && (
                    <div className="order-list">
                        {renderHistoryOrders(history)}
                    </div>
                )}
            </div>
        </div>
    );
};

export default OrderPage;