import React, { useState, useEffect } from 'react';
import './categories.css';
import { toast } from 'react-toastify';
import { AiFillBackward } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { useProducts } from './contexts/productContext';

const CategoriesComponent = ({ categories, loadingCategories, onCategorySelect, activeCategory }) => {
  const [currentCategories, setCurrentCategories] = useState(categories);
  const [makeButtonVisible, setMakeButtonVisible] = useState(false);
  const [prevCategory, setPrevCategory] = useState([]);
  const { t, i18n } = useTranslation();
  const { filterProductsByCategory, filteredProducts, advertisements, loading, setLoading, reload, setReload, error, setProducts } = useProducts();
  // Update currentCategories when categories prop changes
  useEffect(() => {
    setCurrentCategories(categories);
  }, [categories]);

  const handleGoingBack = () => {
    setCurrentCategories(prevCategory)
    setMakeButtonVisible(false)
    setReload(!reload)
  }
  const handleCategoryClick = async (category) => {
    try {
      //fetching products from subcategory
      let response = null;
      if (!category.c_id) {
        response = await fetch(`${process.env.REACT_APP_API}/subcategorywithparentcategoryID_non_auth/${category.id}`);
      }

      if (response) {
        if (response.ok) {
          const subCategories = await response.json();

          if (subCategories && subCategories.length > 0) {
            setLoading(true);
            setPrevCategory(currentCategories);
            setMakeButtonVisible(true);
            setCurrentCategories(subCategories); // Update state with subcategories          

            const product_from_categories = await fetch(`${process.env.REACT_APP_API}/product_no_auth_category/${category.id}`);
            if (!product_from_categories.ok) {
              toast.error('Something went wrong while fetching products by sub-categories data.', {
                position: 'top-right',
                autoClose: 3000, // 3 seconds
              })
            } else {
              const data = await product_from_categories.json()
              setProducts(data);
            }

            setLoading(false)

          } else {
            setPrevCategory(currentCategories);
            setMakeButtonVisible(true);
            setCurrentCategories([category])
            onCategorySelect(category);

            const product_from_categories = await fetch(`${process.env.REACT_APP_API}/product_no_auth_category/${category.id}`);
            if (!product_from_categories.ok) {
              toast.error('Something went wrong while fetching products by categories data.', {
                position: 'top-right',
                autoClose: 3000, // 3 seconds
              })
            } else {
              const data = await product_from_categories.json()
              setProducts(data);
            }
          }
        } else {
          toast.error('Something went wrong while fetching sub-categories data.', {
            position: 'top-right',
            autoClose: 3000, // 3 seconds
          });
        }
      } else {
        const product_from_categories = await fetch(`${process.env.REACT_APP_API}/product_no_auth_subcategory/${category.id}`);
        if (!product_from_categories.ok) {
          toast.error('Something went wrong while fetching products by sub-categories data.', {
            position: 'top-right',
            autoClose: 3000, // 3 seconds
          })
        } else {
          const data = await product_from_categories.json()
          setProducts(data);
        }
      }
    } catch (error) {
      console.error('Error fetching sub-categories:', error);
    }
  };

  return (
    <>
      <div className='header dark:bg-drkmodebrand bg-brand'>
        <h3 className='font-bold'>{t('all_categories')}</h3>
      </div>
      {
        makeButtonVisible &&
        <div className='category_list flex justify-center mt-3'>
          <AiFillBackward className="text-2xl mb-1 dark:text-drkmodebrand text-brand" />
          <a onClick={() => handleGoingBack()} className='font-bold dark:text-drkmodebrand text-brand'>{t('go_back_to_categories')}</a>
        </div>
      }
      <div className={`categories-container ${currentCategories.length > 0 ? 'has-categories' : 'no-categories'}`}>
        {
          loadingCategories
            ?
            (
              <p className='text-center dark:text-gray-200'>{t('loading_categories')} ....</p>
            )
            :
            (
              currentCategories.length > 0
                ? (
                  currentCategories.map(category => (
                    <div
                      key={category.id}
                      className={`category-card ${activeCategory === category.id ? 'active' : ''}`}
                      onClick={() => handleCategoryClick(category)}
                    >
                      <img src={`${process.env.REACT_APP_API}/${category.photo}`} alt={category.name} className="category-image" />
                      <p className="category-name dark:text-gray-200">{category.name}</p>
                    </div>
                  ))
                )
                : (
                  <p className="text-center dark:text-gray-200">{t('no_category')}</p>
                )
            )
        }
      </div>
    </>
  );
}

export default CategoriesComponent;
