import React, { createContext, useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext({
    token: null,
    setToken: () => { },
    login: () => { },
    logout: () => { },
    phonenumber: null,
    firstname: null,
    lastname: null,
    shippingAddress: null,
    showModal: false,
    setShowModal: () => { },
});

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const [token, setTokenState] = useState(localStorage.getItem('token') || null);
    const [userID, setUserID] = useState(null);
    const [shippingAddress, setShippingAddress] = useState('');
    const [phonenumber, setPhoneNumber] = useState('');
    const [firstname, setFirstName] = useState('');
    const [lastname, setLastName] = useState('');
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (token !== null) {
            const fetchUserData = async () => {
                try {
                    const decodedToken = jwtDecode(token);
                    setUserID(decodedToken.id);

                    const response = await fetch(
                        `${process.env.REACT_APP_API}/user/${decodedToken.id}`,
                        {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );

                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }

                    const result = await response.json();
                    setPhoneNumber(result.phonenumber || '');
                    setFirstName(result.firstname || '');
                    setLastName(result.lastname || '');
                    setShippingAddress(result.address || '');
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            };

            fetchUserData();
        }
    }, [token]);

    const setToken = (newToken) => {
        localStorage.setItem('token', newToken);
        setTokenState(newToken);
        const decodedToken = jwtDecode(newToken);
        setUserID(decodedToken.id);
    };

    const login = (userData, tokenData) => {
        setToken(tokenData);
        navigate('/');
    };

    const logout = () => {
        localStorage.removeItem('token');
        setTokenState(null);
        setUserID(null);
        setPhoneNumber('');
        setFirstName('');
        setLastName('');
        setShippingAddress('');
    };

    return (
        <AuthContext.Provider
            value={{
                token,
                setToken,
                login,
                logout,
                phonenumber,
                setPhoneNumber,
                userID,
                setUserID,
                shippingAddress,
                setShippingAddress,
                firstname,
                setFirstName,
                lastname,
                setLastName,
                showModal,
                setShowModal,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export const useAuth = () => useContext(AuthContext);