import React, { createContext, useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';

// Create the context
const CategoryContext = createContext();

// Provider component
export const CategoryProvider = ({ children }) => {
  const [categories, setCategories] = useState([]);
  const [loadingCategory, setLoadingCategory] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      setLoadingCategory(true);
      try {
        // Fetch products from remote server
        const response = await fetch(`${process.env.REACT_APP_API}/categories_non_auth`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        });

        if (!response.ok) {
          toast.error('Something went wrong while fetching categories data.', {
            position: 'top-right',
            autoClose: 3000, // 3 seconds
          });
        }

        const data = await response.json();

        setCategories(data);
        // Fetch products from AsyncStorage
      } catch (e) {
        setError('Failed to fetch products.');
      } finally {
        setLoadingCategory(false);
      }
    };

    fetchCategories();
  }, []);

  return (
    <CategoryContext.Provider value={{ categories, loadingCategory, setLoadingCategory, error }}>
      {children}
    </CategoryContext.Provider>
  );
};

// Custom hook to use the ProductContext
export const useCategories = () => {
  return useContext(CategoryContext);
};
