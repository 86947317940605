const productReducer = (state, action) => {
  switch (action.type) {
    case 'SET_PRODUCTS':
      return {
        ...state,
        products: action.payload,
        filteredProducts: action.payload, // Initialize filteredProducts with all products
      };
    case 'FILTER_BY_CATEGORY':
      const filtered = action.payload === null
        ? state.products
        : state.products.filter(product => product.category == action.payload);
      return {
        ...state,
        filteredProducts: filtered,
      };
    case 'SET_FILTERED_PRODUCTS':
      return {
        ...state,
        filteredProducts: action.payload, // Initialize filteredProducts with all products
      };
    case 'SET_ADVERTISEMENTS':
      return {
        ...state,
        advertisements: action.payload,
      };
    default:
      return state;
  }
};

export default productReducer;

