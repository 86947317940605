import React, { useState, useEffect } from "react"
import * as Yup from 'yup';
import { useAuth } from "./contexts/authContext";
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";

const Account = () => {
    const [loading, setLoading] = useState(false)
    const { t, i18n } = useTranslation();
    const { firstname, lastname, shippingAddress, phonenumber, token } = useAuth()
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        firstname: "",
        lastname: "",
        phoneNumber: "",
        shippingAddress: ""
    });

    if (!token) {
        navigate('/')
    }

    function removeCountryCode(phoneNumber) {
        const countryCode = "+251";
        if (phoneNumber.startsWith(countryCode)) {
            return phoneNumber.slice(countryCode.length);
        }
        return phoneNumber;
    }


    useEffect(() => {
        if (firstname && lastname && phonenumber && shippingAddress) {
            setFormData({
                firstname,
                lastname,
                phoneNumber: removeCountryCode(phonenumber),
                shippingAddress,
            });
        }
    }, [firstname, lastname, phonenumber, shippingAddress]);

    const [errors, setErrors] = useState({});

    const validationSchema = Yup.object({
        firstname: Yup.string().required(t('first_name_required')),
        lastname: Yup.string().required(t('last_name_required')),
        phoneNumber: Yup.string()
            .matches(/^(9|7)\d{8}$/, t('phone_number_invalid_regex'))
            .required(t('phone_number_required')),
        shippingAddress: Yup.string().required(t('shipping_address_required')),

    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const body = {
                firstname: formData.firstname,
                lastname: formData.lastname,
                phonenumber: formData.phoneNumber,
                address: formData.shippingAddress,
            }

            await validationSchema.validate(formData, { abortEarly: false });

            try {
                const userID = jwtDecode(token).id
                
                const response = await fetch(`${process.env.REACT_APP_API}/user/${userID}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(body),
                });

                const data = await response.json();
                console.log(data);


                if (data.code === 409) {
                    setErrors(prevErrors => ({
                        ...prevErrors,
                        phoneNumber: t('not_registered_phone_number'),
                    }));
                } else {
                    setErrors({});
                    toast.success('Data update successfully!', {
                        position: 'top-right',
                        autoClose: 3000, // 3 seconds
                    });
                }
            } catch (error) {
                toast.error(error, {
                    position: 'top-right',
                    autoClose: 3000, // 3 seconds
                });
            }
        } catch (error) {
            toast.error(error, {
                position: 'top-right',
                autoClose: 3000, // 3 seconds
            });
        } finally {
            setLoading(false);
        }
    };

    const handleChange = async (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <>
            <div className="container dark:bg-drkmode">
                <div className="grid col-span-1 md:grid-cols-3">
                    <div className="first"></div>
                    <div className="second">
                        <div className="header">
                            <p className="text-center text-xl font-bold mt-8 dark:text-gray-200">{t('personal_information')}</p>
                        </div>
                        <form className="m-5 p-5" onSubmit={handleSubmit} method="post">
                            <div className="grid grid-cols-2 space-x-5">
                                <div className="group-one">
                                    <label htmlFor="" className="dark:text-gray-200">{t('first_name')}</label>
                                    <input className="border border-gray-300 rounded p-2 w-full" type="text" name="firstname" onChange={handleChange} value={formData.firstname} placeholder={t('first_name')} />
                                    {errors.firstname && (
                                        <div className="error">{errors.firstname}</div>
                                    )}
                                </div>
                                <div className="group-two">
                                    <label htmlFor="" className="dark:text-gray-200">{t('last_name')}</label>
                                    <input className="border border-gray-300 rounded p-2 w-full" type="text" name="lastname" onChange={handleChange} value={formData.lastname} placeholder={t('last_name')} />
                                    {errors.lastname && (
                                        <div className="error">{errors.lastname}</div>
                                    )}
                                </div>
                            </div>
                            <div className="group-three mt-3">
                                <label htmlFor="" className="dark:text-gray-200">{t('mobile_number')}</label>
                                <input className="border border-gray-300 rounded p-2 w-full" type="text" name="phoneNumber" onChange={handleChange} value={formData.phoneNumber} placeholder={t('mobile_number')} />
                                {errors.phoneNumber && (
                                    <div className="error">{errors.phoneNumber}</div>
                                )}
                            </div>
                            <div className="group-four mt-3">
                                <label htmlFor="" className="dark:text-gray-200">{t('shipping_address')}</label>
                                <select className="border border-gray-300 rounded p-2 w-full" onChange={handleChange} value={formData.shippingAddress} name="shippingAddress">
                                    <option value="">{t('select_shipping_address')}</option>
                                    <option value="Addis Ababa Region">{t('addis_ababa')}</option>
                                    <option value="Amhara Region">{t('amhara')}</option>
                                    <option value="Benishangul-Gumuz Region">{t('benishangul_gumuz')}</option>
                                    <option value="Dire Dawa Region">{t('dire_dawa')}</option>
                                    <option value="Gambela Region">{t('gambela')}</option>
                                    <option value="Harari Region">{t('harari')}</option>
                                    <option value="Oromia Region">{t('oromia')}</option>
                                    <option value="SNNPR">{t('snnpr')}</option>
                                    <option value="Somali Region">{t('somali')}</option>
                                    <option value="Tigray Region">{t('tigray')}</option>
                                </select>
                                {errors.shippingAddress && (
                                    <div className="error">{errors.shippingAddress}</div>
                                )}
                            </div>
                            <div className="group-five">
                                {
                                    loading
                                        ?
                                        (<button disabled={loading} className="p-2 text-white rounded w-full mt-5 bg-brand dark:bg-drkmodebrand">{t('updating')} ......</button>)
                                        :
                                        (<button type="submit" className="p-2 text-white rounded w-full mt-5 bg-brand dark:bg-drkmodebrand">{t('update')}</button>)
                                }

                            </div>
                        </form>
                    </div>
                    <div className="third"></div>
                </div>
            </div>
        </>
    )
}

export default Account