import React from 'react';
import { useTranslation } from 'react-i18next';
const Pagination = ({ productsPerPage, totalProducts, paginate, currentPage }) => {
    const totalPages = Math.ceil(totalProducts / productsPerPage);
    const pageNumbers = [];
    const { t, i18n } = useTranslation();

    if (totalPages > 1) {
        pageNumbers.push(1); // Always show the first page

        // Logic to display current page only if there are more than 2 pages
        if (totalPages <= 3) {
            // If total pages are 3 or fewer, display them all
            for (let i = 2; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            // If more than 3 pages, show current page in the middle
            if (currentPage > 1 && currentPage < totalPages) {
                // Show dots if the current page is greater than 2
                if (currentPage > 2) {
                    pageNumbers.push('...'); // Show dots before the current page if applicable
                }
                pageNumbers.push(currentPage); // Add the current page
            }

            // Show dots if necessary after the current page
            if (currentPage < totalPages - 1) {
                pageNumbers.push('...'); // Show dots after the current page if applicable
            }
        }

        pageNumbers.push(totalPages); // Always show the last page
    }

    return (
        <nav className="flex flex-col items-center mt-10">
            <ul className="flex flex-wrap justify-center items-center space-x-2">
                <li>
                    <button
                        onClick={() => paginate(currentPage - 1)}
                        disabled={currentPage === 1}
                        className={`px-3 py-1 rounded-full ${currentPage === 1 ? 'bg-gray-300 cursor-not-allowed' : 'dark:bg-drkmodebrand bg-brand text-white hover:bg-blue-700'}`}
                    >
                        {t('previous_page')}
                    </button>
                </li>
                {pageNumbers.map((number, index) => (
                    <li key={index}>
                        {typeof number === 'string' ? (
                            <span className="px-3 py-1 dark:text-white text-brand">...</span>
                        ) : (
                            <button
                                onClick={() => paginate(number)}
                                className={`px-3 py-1 rounded-full ${currentPage === number ? 'dark:bg-drkmodebrand bg-brand text-white' : 'dark:bg-white dark:text-drkmodebrand text-brand hover:bg-blue-200'}`}
                            >
                                {number}
                            </button>
                        )}
                    </li>
                ))}
                <li>
                    <button
                        onClick={() => paginate(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        className={`px-3 py-1 rounded-full ${currentPage === totalPages ? 'bg-gray-300 cursor-not-allowed' : 'dark:bg-drkmodebrand bg-brand text-white'}`}
                    >
                        {t('next_page')}
                    </button>
                </li>
            </ul>
        </nav>
    );
};

export default Pagination;
