import React from 'react'
import Nav from './components/nav'
import { BrowserRouter } from 'react-router-dom'
import Rout from './components/rout'
import Footer from './components/footer'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CartProvider } from './components/contexts/cartContext';
import { ProductProvider } from './components/contexts/productContext';
import { CategoryProvider } from './components/contexts/categoryContext';
import { OrderProvider } from './components/contexts/orderContext';
import { WishlistProvider } from './components/contexts/wishlistContext';
import { AuthProvider } from './components/contexts/authContext';

const App = () => {
  return (
    <>
      <BrowserRouter>
        <AuthProvider>
          <WishlistProvider>
            <CategoryProvider>
              <ProductProvider>
                <CartProvider>
                  <OrderProvider>
                    <ToastContainer />
                    <Nav />
                    <Rout />
                    <Footer />
                  </OrderProvider>
                </CartProvider>
              </ProductProvider>
            </CategoryProvider>
          </WishlistProvider>
        </AuthProvider>
      </BrowserRouter>
    </>
  )
}

export default App
