import React, { useState } from 'react'
import './contact.css'
const Contact = () => {

    const [user, setUser] = useState(
        {
            Name: '', email: '', subject: '', Message: ''
        }
    )
    let values, names
    const data = (e) => {
        values = e.target.value
        names = e.target.name
        setUser({ ...user, [names]: values })
    }

    const send = async (e) => {
        const { Name, email, subject, Message } = user
        e.preventDefault()
        const option = {
            method: 'POST',
            headers: {
                'Content-Type': 'aplication/json'
            },
            body: JSON.stringify({
                Name, email, subject, Message
            })
        }

        const send = await fetch(
            'https://react-ecommerce-contact-default-rtdb.firebaseio.com/Message.json', option
        )

        if (send) {
            alert("Message Sent")
        }
        else {
            alert("Error Occoured Message Sent failed")
        }

    }

    return (
        <>
            <div className='contact'>
                <div className='container'>
                    <div className="grid grid-cols-1 md:grid-cols-3">
                    <div className="col-1"></div>
                        <div className='form'>
                            <form method='POST'>
                            <div className="heading">
                                <p className='font-bold text-center'>Get In Touch</p>
                            </div>
                                <div className='box'>
                                    <div className='lable'>
                                        <h4>Name</h4>
                                    </div>
                                    <div className='input'>
                                        <input className="border border-gray-300 rounded p-2 w-full" type='text' placeholder='Name' value={user.Name} name='Name' onChange={data}></input>
                                    </div>
                                </div>
                                <div className='box'>
                                    <div className='lable'>
                                        <h4>E-mail</h4>
                                    </div>
                                    <div className='input'>
                                        <input className="border border-gray-300 rounded p-2 w-full" type='email' placeholder='E-mail' value={user.email} name='email' onChange={data}></input>
                                    </div>
                                </div>
                                <div className='box'>
                                    <div className='lable'>
                                        <h4>Subject</h4>
                                    </div>
                                    <div className='input'>
                                        <input className="border border-gray-300 rounded p-2 w-full" type='text' placeholder='Subject' value={user.subject} name='subject' onChange={data}></input>
                                    </div>
                                </div>
                                <div className='box'>
                                    <div className='lable'>
                                        <h4>Message</h4>
                                    </div>
                                    <div className='input'>
                                        <textarea className="border border-gray-300 rounded p-2 w-full" placeholder='Message !' value={user.Message} name='Message' onChange={data}></textarea>
                                    </div>
                                </div>
                                <div className="button">
                                    <button type='sublit' onClick={send}>Send</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-1"></div>
                </div>
            </div>
        </>
    )
}

export default Contact