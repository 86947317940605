import React, { useState, useEffect } from "react"
import * as Yup from 'yup';
import './registration.css'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Registration = () => {
    const [loading, setLoading] = useState(false)
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        shippingAddress: "",
    });

    const [formOTPData, setFormOTPData] = useState({
        otp: "123",
    });

    const [errors, setErrors] = useState({});

    const validationSchema = Yup.object({
        firstName: Yup.string().required(t('first_name_required')),
        lastName: Yup.string().required(t('last_name_required')),
        phoneNumber: Yup.string()
            .matches(/^(9|7)\d{8}$/, t('phone_number_invalid_regex'))
            .required(t('phone_number_required')),
        shippingAddress: Yup.string().required(t('shipping_address_required')),
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            await validationSchema.validate(formData, { abortEarly: false });
            setErrors({});

            const mobileVerificationResponse = await verifyMobile(formData.phoneNumber);
            if (mobileVerificationResponse.code === 409) {
                handlePhoneNumberExistError();
                return;
            }

            await signupCustomer(formData, formOTPData.otp);
            navigate('/login');

        } catch (error) {
            handleValidationErrors(error);
        } finally {
            setLoading(false);
        }
    };

    const verifyMobile = async (phoneNumber) => {
        const response = await fetch(`${process.env.REACT_APP_API}/verify_mobile`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ phonenumber: phoneNumber }),
        });
        return await response.json();
    };

    const handlePhoneNumberExistError = () => {
        setErrors(prevErrors => ({
            ...prevErrors,
            phoneNumber: t('phone_number_already_exist'),
        }));
    };

    const signupCustomer = async (formData, otp) => {
        const customerData = {
            firstname: formData.firstName,
            lastname: formData.lastName,
            phonenumber: formData.phoneNumber,
            address: formData.shippingAddress,
            otp: otp
        };

        const response = await fetch(`${process.env.REACT_APP_API}/mobile_signup`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(customerData),
        });

        const data = await response.json();
        if (data.code === 500) {
            setErrors(prevErrors => ({
                ...prevErrors,
                address: t('general_error'),
            }));
        }
    };

    const handleValidationErrors = (error) => {
        const newErrors = {};
        error.inner.forEach((err) => {
            newErrors[err.path] = err.message;
        });
        setErrors(newErrors);
    };

    const handleChange = async (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <>
            <div className="container dark:bg-drkmode">
                <div className="grid col-span-1 md:grid-cols-3">
                    <div className="first"></div>
                    <div className="second">
                        <div className="header">
                            <p className="text-center text-xl font-bold mt-8 dark:text-gray-200">{t('registration')}</p>
                        </div>
                        <form className="m-5 p-5" onSubmit={handleSubmit} method="post">
                            <div className="grid grid-cols-2 space-x-5">
                                <div className="group-one">
                                    <label htmlFor="" className="dark:text-gray-200">{t('first_name')}</label>
                                    <input className="border border-gray-300 rounded p-2 w-full" onChange={handleChange} name="firstName" type="text" value={formData.firstName} placeholder={t('first_name')} />
                                    {errors.firstName && <div className="error">{errors.firstName}</div>}
                                </div>
                                <div className="group-two">
                                    <label htmlFor="" className="dark:text-gray-200">{t('last_name')}</label>
                                    <input className="border border-gray-300 rounded p-2 w-full" onChange={handleChange} name="lastName" type="text" value={formData.lastName} placeholder={t('last_name')} />
                                    {errors.lastName && <div className="error">{errors.lastName}</div>}
                                </div>
                            </div>
                            <div className="group-three mt-3">
                                <label htmlFor="" className="dark:text-gray-200">{t('mobile_number')}</label>
                                <input className="border border-gray-300 rounded p-2 w-full" onChange={handleChange} name="phoneNumber" type="text" value={formData.phoneNumber} placeholder={t('mobile_number')} />
                                {errors.phoneNumber && (
                                    <div className="error">{errors.phoneNumber}</div>
                                )}
                            </div>
                            <div className="group-four mt-3">
                                <label htmlFor="" className="dark:text-gray-200">{t('shipping_address')}</label>
                                <select className="border border-gray-300 rounded p-2 w-full" onChange={handleChange} name="shippingAddress">
                                    <option value="">{t('select_shipping_address')}</option>
                                    <option value="Addis Ababa Region">{t('addis_ababa')}</option>
                                    <option value="Amhara Region">{t('amhara')}</option>
                                    <option value="Benishangul-Gumuz Region">{t('benishangul_gumuz')}</option>
                                    <option value="Dire Dawa Region">{t('dire_dawa')}</option>
                                    <option value="Gambela Region">{t('gambela')}</option>
                                    <option value="Harari Region">{t('harari')}</option>
                                    <option value="Oromia Region">{t('oromia')}</option>
                                    <option value="SNNPR">{t('snnpr')}</option>
                                    <option value="Somali Region">{t('somali')}</option>
                                    <option value="Tigray Region">{t('tigray')}</option>
                                </select>
                                {errors.shippingAddress && <div className="error">{errors.shippingAddress}</div>}
                            </div>
                            <div className="group-five">
                                {
                                    loading
                                        ?
                                        (
                                            <button disabled={loading} className="p-2 text-white rounded w-full mt-5 bg-brand dark:bg-drkmodebrand" type="submit">{t('sending_otp')} ......</button>
                                        )
                                        : (
                                            <button className="p-2 text-white rounded w-full mt-5 bg-brand dark:bg-drkmodebrand" type="submit">{t('register')}</button>
                                        )
                                }

                            </div>
                            <div className="mt-5 text-center">
                                <a href="/login" className="p-2 rounded w-full text-sm text-brand dark:text-drkmodebrand">{t('already_have_account')}</a>
                            </div>
                        </form>

                    </div>
                    <div className="third"></div>
                </div>
            </div>
        </>
    )
}

export default Registration