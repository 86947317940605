import React, { useEffect, useState } from 'react';
import './footer.css';
import { FaPiggyBank, FaShippingFast, FaHeadphonesAlt } from 'react-icons/fa';
import { useAuth } from "./contexts/authContext";
import { useTranslation } from 'react-i18next';
import { AiOutlineFacebook, AiOutlineSend, AiOutlineTikTok, AiOutlineWhatsApp } from 'react-icons/ai';
import { toast } from 'react-toastify';

const Footer = () => {
  const { token } = useAuth()
  const { t, i18n } = useTranslation();
  const [appStore, setAppStore] = useState('');
  const [playStore, setPlayStore] = useState('');

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}/system_info_no_auth/1`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        });

        if (!response.ok) {
          toast.error('Something went wrong while fetching system info data.', {
            position: 'top-right',
            autoClose: 3000, // 3 seconds
          });
        }

        const data = await response.json();

        setPlayStore(data.link_android);
        setAppStore(data.link_ios);

      } catch (e) {
        toast.error('Something went wrong while fetching system info data.', {
          position: 'top-right',
          autoClose: 3000, // 3 seconds
        });
      }
    };

    fetchProducts();
  }, [token])

  return (
    <div className='footer dark:text-gray-200 dark:bg-drkmode'>
      <div className='footer-top'>
        <div className='service'>
          <div className="grid grid-cols-3">
            <div className='service-item'>
              <FaPiggyBank className='text-brand dark:text-drkmodebrand' />
              <h3 className='text-brand dark:text-drkmodebrand'>{t('great_savings')}</h3>
            </div>
            <div className='service-item'>
              <FaShippingFast className='text-brand dark:text-drkmodebrand' />
              <h3 className='text-brand dark:text-drkmodebrand'>{t('free_delivery')}</h3>
            </div>
            <div className='service-item'>
              <FaHeadphonesAlt className='text-brand dark:text-drkmodebrand' />
              <h3 className='text-brand dark:text-drkmodebrand'>24/7 {t('support')}</h3>
            </div>
          </div>
        </div>
      </div>
      <div className='footer-bottom'>
        <div className='footer-logo'>
          <img src='image/logo.png' alt='Company Logo' />
          <p>{t('providing_the_best_electronics_and_exceptional_service')}.</p>
        </div>
        <div className='footer-links mb-16 md:mb-0 mt-5'>
          <div className="grid sm:grid-cols-4 grid-cols-1">
            <div className='links-group'>
              <h3>{t('quick_links')}</h3>
              <ul>
                <li className='dark:hover:text-drkmodebrand hover:text-brand'>
                  <a href="/">{t('home')}</a>
                </li>
                <li className='dark:hover:text-drkmodebrand hover:text-brand'>
                  <a href="/cart">{t('cart')}</a>
                </li>
                <li className='dark:hover:text-drkmodebrand hover:text-brand'>
                  <a href="/order">{t('order')}</a>
                </li>
                {
                  token &&
                  (
                    <>
                      <li className='dark:hover:text-drkmodebrand hover:text-brand'>
                        <a href="/wishlist">{t('wishlist')}</a>
                      </li>
                      <li className='dark:hover:text-drkmodebrand hover:text-brand'>
                        <a href="/account">{t('my_account')}</a>
                      </li>
                    </>
                  )
                }
              </ul>
            </div>
            <div className='links-group'>
              <h3>{t('contact_us')}</h3>
              <ul>
                <li className='dark:hover:text-drkmodebrand hover:text-brand'>{t('sidamo_tera_kuter_1_addis_ababa_ethiopia')}</li>
                <li className='dark:hover:text-drkmodebrand hover:text-brand'>+251947323237 / 9297</li>
              </ul>
            </div>
            <div className='links-group'>
              <h3>{t('social_media')}</h3>
              <ul className='flex justify-between sm:w-48'>
                <li className='dark:hover:text-drkmodebrand hover:text-brand'><a target='_blank' href="https://www.facebook.com/share/g/fz6kT6RzQkXFvZqp/?mibextid=K35XfP" rel="noreferrer"><AiOutlineFacebook className='text-brand dark:text-drkmodebrand text-xl' />Facebook</a></li>
                <li className='dark:hover:text-drkmodebrand hover:text-brand'><a target='_blank' href="https://www.tiktok.com/@abet9297" rel="noreferrer"><AiOutlineTikTok className='text-brand dark:text-drkmodebrand text-xl' />TikTok</a></li>
                <li className='dark:hover:text-drkmodebrand hover:text-brand'><a target='_blank' href="https://t.me/abetelectronics" rel="noreferrer"><AiOutlineSend className='text-brand dark:text-drkmodebrand text-xl' />Telegram</a></li>
              </ul>
            </div>
            <div className='links-group'>
              <h3>{t('footer_title_app')}</h3>
              <a href={playStore} target='_blank' className='flex justify-center'>
                <img className='app' src="image/playstore.png" alt="" />
              </a>
              <a href={appStore} target='_blank' className='flex justify-center'>
                <img className='app' src="image/appstore.png" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
