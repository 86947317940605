import React, { useState } from 'react';
import { AiOutlineHeart, AiOutlineLogout, AiOutlineOrderedList, AiOutlineSearch, AiOutlineTranslation, AiOutlineUser } from 'react-icons/ai';
import { NavLink } from 'react-router-dom';
import { AiOutlineHome, AiOutlineShoppingCart, AiOutlineInfoCircle, AiOutlineMail } from 'react-icons/ai';
import './nav.css';
import { useAuth } from './contexts/authContext';
import { useCart } from './contexts/cartContext';
import { useProducts } from './contexts/productContext';
import { useTranslation } from 'react-i18next';
import ThemeToggle from './theme/ThemeToggle';

const Nav = () => {
  const { token, logout, firstname, lastname, showModal, setShowModal } = useAuth();
  const { searchProducts, setSearching } = useProducts();
  const [search, setSearch] = useState('');
  const { cart } = useCart();
  const count = Object.keys(cart).length;
  const { t, i18n } = useTranslation();

  const handleInputChange = (e) => {
    const searchTerm = e.target.value;
    setSearch(searchTerm);
    searchTerm ? setSearching(true) : setSearching(false);
    searchProducts(searchTerm);
  };

  const changeLanguage = (lng) => {
    console.log(lng);
    i18n.changeLanguage(lng);
  };

  const handleSearchClick = () => {
    const searchlength = (search || []).length === 0
    if (searchlength) {
      alert("Please Search Something !")
    } else {
      searchProducts(search);
    }
  };
  return (
    <>
      {showModal && (
        <div className="modal dark:text-gray-200 dark:bg-drkmode">
          <div className="modal-content">
            <span className="close" onClick={() => setShowModal(false)}>
              &times;
            </span>
            <p className='dark:text-gray-200 p-3'>{t('are_u_sure')}</p>
            <div className="dark:text-gray-200 dark:bg-drkmode space-x-4 flex justify-end">
              <button onClick={() => {
                setShowModal(false)
                logout()
              }} className='btn dark:bg-drkmodebrand bg-brand text-white p-3'>{t('yes')}</button>
              <button onClick={() => setShowModal(false)}>{t('cancel')}</button>
            </div>
          </div>
        </div>
      )}
      <header className='header'>
        <div className='top-header flex justify-between items-center p-4 dark:bg-drkmode'>
          <div className='logo'>
            <img src='image/logo.png' alt='Abet Electronics Logo' className='w-16' />
          </div>
          <div className='user flex items-center'>
            <div className="flex space-x-5">
              <ThemeToggle />
              {/* <AiOutlineTranslation className='text-2xl dark:text-gray-200' /> */}
              <select className='dark:bg-drkmode dark:text-gray-200' name="language" id="language" value={i18n.language} onChange={(e) => changeLanguage(e.target.value)}>
                <option value="en">English</option>
                <option value="am">Amharic</option>
              </select>
              {token ? (
                <>
                  <p className='userAccount dark:text-gray-200 mt-2'>{t('hi')}, {firstname} {lastname}</p>
                  <button className='btn' onClick={() => setShowModal(true)}>
                    <AiOutlineLogout className='text-lg dark:text-gray-200 text-brand' />
                  </button>
                </>
              ) : (
                <>
                  <NavLink exact="true" to="/login" className={'text-brand dark:text-gray-200 font-bold mt-2'}>{t('login')}</NavLink>
                </>
              )}
            </div>
          </div>
        </div>
        <div className='mid-header grid place-items-center dark:bg-drkmode'>
          <div className='search-box flex items-center'>
            <input
              type='text'
              value={search}
              placeholder={`${t('search_products')} ...`}
              onChange={handleInputChange}
              className='p-3 border rounded-l md:w-96 sm:w-16'
            />
            <button onClick={handleSearchClick} className='p-2 bg-blue-500 text-white rounded-r dark:bg-drkmode'>
              <AiOutlineSearch />
            </button>
          </div>
        </div>

        <div className='bottom-header dark:bg-drkmode'>
          <nav className='nav'>
            <ul>
              <li>
                <NavLink to='/' className={({ isActive }) => isActive ? 'nav-link flex flex-col items-center dark:!text-drkmodebrand !text-yellow-400' : 'text-white nav-link flex flex-col items-center'}>
                  <AiOutlineHome className='text-2xl mb-1' />{t('home')}
                </NavLink>
              </li>
              <li className="relative">
                <NavLink
                  to="/cart"
                  className={({ isActive }) =>
                    isActive
                      ? 'nav-link flex flex-col items-center dark:!text-drkmodebrand !text-yellow-400'
                      : 'text-white nav-link flex flex-col items-center'
                  }
                >
                  <AiOutlineShoppingCart className="text-2xl mb-1" />
                  {t('cart')}
                  {count > 0 && (
                    <span className="text-xs text-white rounded-full px-2 shopping_counter bg-red-600 dark:bg-red-600 dark:text-white">
                      {count}
                    </span>
                  )}
                </NavLink>
              </li>
              <li><NavLink exact="true" to='/order' className={({ isActive }) => isActive ? 'nav-link flex flex-col items-center dark:!text-drkmodebrand !text-yellow-400' : 'text-white nav-link flex flex-col items-center'}>
                <AiOutlineOrderedList className='text-2xl mb-1' />
                {t('order')}
              </NavLink>
              </li>
              {token && <li><NavLink exact="true" to='/wishlist' className={({ isActive }) => isActive ? 'nav-link flex flex-col items-center dark:!text-drkmodebrand !text-yellow-400' : 'text-white nav-link flex flex-col items-center'}>
                <AiOutlineHeart className='text-2xl mb-1' />
                {t('wishlist')}
              </NavLink>
              </li>}
              {token && <li>
                <NavLink exact="true" to='/account' className={({ isActive }) => isActive ? 'nav-link flex flex-col items-center dark:!text-drkmodebrand !text-yellow-400' : 'text-white nav-link flex flex-col items-center'}>
                  <AiOutlineUser className='text-2xl mb-1' />
                  {t('my_account')}
                </NavLink>
              </li>}
              {/* <li><NavLink exact="true" to='/about' className={({ isActive }) => isActive ? 'nav-link flex flex-col items-center !text-yellow-400' : 'text-white nav-link flex flex-col items-center'}>
                <AiOutlineInfoCircle className='text-2xl mb-1' />
                About Us
              </NavLink>
              </li> */}
              {/* <li><NavLink to='/contact' className={({ isActive }) => isActive ? 'nav-link flex flex-col items-center !text-yellow-400' : 'text-white nav-link flex flex-col items-center'}>
                <AiOutlineMail className='text-2xl mb-1' />
                Contact Us
              </NavLink>
              </li> */}
            </ul>
          </nav>
        </div>
      </header>
      <footer className='bottom-nav flex justify-around p-4 text-white dark:bg-drkmode'>
        <NavLink
          exact="true"
          to='/'
          className={({ isActive }) => isActive ? 'nav-link flex flex-col items-center dark:!text-drkmodebrand !text-yellow-400' : 'text-white nav-link flex flex-col items-center'}
        >
          <AiOutlineHome className='text-lg mb-1' />
          <span className='text-xs'>{t('home')}</span>
        </NavLink>

        <NavLink
          to="/cart"
          className={({ isActive }) =>
            isActive
              ? 'nav-link flex flex-col items-center dark:!text-drkmodebrand !text-yellow-400 relative'
              : 'text-white nav-link flex flex-col items-center relative'
          }
        >
          <AiOutlineShoppingCart className="text-lg mb-1" />
          <span className='text-xs'>{t('cart')}</span>
          {count > 0 && (
            <span className="text-xs text-white rounded-full px-2 shopping_counter bg-red-600 dark:bg-red-600 dark:text-white">
              {count}
            </span>
          )}
        </NavLink>


        <NavLink exact="true" to='/order' className={({ isActive }) => isActive ? 'nav-link flex flex-col items-center dark:!text-drkmodebrand !text-yellow-400' : 'text-white nav-link flex flex-col items-center'}>
          <AiOutlineOrderedList className='text-lg mb-1' />
          <span className='text-xs'>{t('order')}</span>
        </NavLink>

        {token && <NavLink exact="true" to='/wishlist' className={({ isActive }) => isActive ? 'nav-link flex flex-col items-center dark:!text-drkmodebrand !text-yellow-400' : 'text-white nav-link flex flex-col items-center'}>
          <AiOutlineHeart className='text-lg mb-1' />
          <span className='text-xs'>{t('wishlist')}</span>
        </NavLink>}

        {token && <NavLink exact="true" to='/account' className={({ isActive }) => isActive ? 'nav-link flex flex-col items-center dark:!text-drkmodebrand !text-yellow-400' : 'text-white nav-link flex flex-col items-center'}>
          <AiOutlineUser className='text-lg mb-1' />
          <span className='text-xs'>{t('my_account')}</span>
        </NavLink>}

        {/* <NavLink exact="true"
          to='/about'
          className={({ isActive }) => isActive ? 'nav-link flex flex-col items-center !text-yellow-400' : 'text-white nav-link flex flex-col items-center'}

        >
          <AiOutlineInfoCircle className='text-lg mb-1' />
          <span className='text-xs'>About</span>
        </NavLink> */}

        {/* <NavLink
          to='/contact'
          className={({ isActive }) => isActive ? 'nav-link flex flex-col items-center !text-yellow-400' : 'text-white nav-link flex flex-col items-center'}
          
        >
          <AiOutlineMail className='text-lg mb-1' />
          <span className='text-xs'>Contact</span>
        </NavLink> */}
      </footer>
    </>
  );
};

export default Nav;
