import React, { createContext, useState, useContext, useEffect } from 'react';

// Create a Context for the Cart
const CartContext = createContext();

// Create a Provider Component
export const CartProvider = ({ children }) => {
    const [cart, setCart] = useState(JSON.parse(localStorage.getItem('cart')) || {});

    const getLocalStorageDiscount = () => {
        const storedDiscount = localStorage.getItem('additional_discount');
        return storedDiscount ? JSON.parse(storedDiscount) : []; // Default to an empty array if not found
    };

    const getUsedCouponCode = () => {
        const couponCode = localStorage.getItem('used_coupon_code');
        return couponCode ? JSON.parse(couponCode) : []; // Default to an empty array if not found
    };

    const [additionalDiscount, setAdditionalDiscount] = useState(getLocalStorageDiscount);
    const [useCouponCode, setUsedCouponCode] = useState(getUsedCouponCode);

    useEffect(() => {
        localStorage.setItem('cart', JSON.stringify(cart));
        localStorage.setItem('additional_discount', JSON.stringify(additionalDiscount));
        localStorage.setItem('used_coupon_code', JSON.stringify(useCouponCode));

        // Check if cart is empty and only update usedCouponCode if needed
        const isEmpty = (obj) => Object.keys(obj).length === 0;
        if (isEmpty(cart) && useCouponCode.length > 0) {
            setUsedCouponCode([]); // Only update if useCouponCode is not already empty
        }

    }, [cart, additionalDiscount, useCouponCode]);


    const clearCart = () => {
        setCart({});
        setAdditionalDiscount([]);
        setUsedCouponCode([]);
    };

    const addToCart = (product) => {
        setCart(prevCart => ({
            ...prevCart,
            [product.id]: {
                ...product,
                qty: (prevCart[product.id]?.qty || 0) + product.min_order
            }
        }));
    };

    const removeFromCart = (product) => {
        setCart(prevCart => {
            const newCart = { ...prevCart };
            if (newCart[product.id]) {
                delete newCart[product.id];
            }
            return newCart;
        });

        setAdditionalDiscount([])

        // Update setUsedCouponCode to remove only the matching product ID
        setUsedCouponCode(prevCoupons => {
            return prevCoupons.filter(coupon => coupon.p_id !== product.id);
        });
    };

    const increaseQty = (product) => {
        setCart(prevCart => ({
            ...prevCart,
            [product.id]: {
                ...product,
                qty: prevCart[product.id].qty + 1
            }
        }));
    };

    const increaseQtyInput = (product, newQty) => {
        setCart(prevCart => ({
            ...prevCart,
            [product.id]: {
                ...product,
                qty: newQty
            }
        }));
    };

    const decreaseQty = (product) => {
        setCart(prevCart => {
            if (prevCart[product.id]?.qty > 1) {
                return {
                    ...prevCart,
                    [product.id]: {
                        ...product,
                        qty: prevCart[product.id].qty - 1
                    }
                };
            } else {
                const newCart = { ...prevCart };
                delete newCart[product.id];
                return newCart;
            }
        });
    };

    const decreaseQtyInput = (product, newQty) => {
        setCart(prevCart => {
            if (prevCart[product.id]?.qty >= 1) {
                return {
                    ...prevCart,
                    [product.id]: {
                        ...product,
                        qty: newQty
                    }
                };
            } else {
                const newCart = { ...prevCart };
                delete newCart[product.id];
                return newCart;
            }
        });
    };

    const getQuantity = (product) => {
        return cart[product.id]?.qty || 0;
    };

    return (
        <CartContext.Provider value={{ cart, addToCart, removeFromCart, increaseQty, increaseQtyInput, decreaseQty, decreaseQtyInput, getQuantity, clearCart, additionalDiscount, setAdditionalDiscount, useCouponCode, setUsedCouponCode }}>
            {children}
        </CartContext.Provider>
    );
};

// Custom Hook to use Cart Context
export const useCart = () => {
    return useContext(CartContext);
};