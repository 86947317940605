import React, { useEffect, useState } from 'react';
import './cart.css';
import { Link } from 'react-router-dom';
import { AiOutlineClose } from 'react-icons/ai';
import { useCart } from './contexts/cartContext';
import { useNavigate } from 'react-router-dom';
import { useOrder } from './contexts/orderContext';
import { useAuth } from "./contexts/authContext";
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';

const Cart = () => {
    const { cart, increaseQty, addToCart, getQuantity, decreaseQty, decreaseQtyInput, increaseQtyInput, removeFromCart, additionalDiscount, setAdditionalDiscount, useCouponCode, setUsedCouponCode } = useCart();
    const { token, userID } = useAuth()
    const { createOrder } = useOrder();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState('');
    const [productID, setProductID] = useState('');
    const [couponCode, setCouponCode] = useState('');
    const [total, setTotal] = useState(0);
    const [forceUpdate, setForceUpdate] = useState(false);
    const [loadingTotal, setLoadingTotal] = useState(false);
    const [originalTotal, setOriginalTotal] = useState(0);

    useEffect(() => {
        let new_total = 0; // Initialize the total
        let original_total = 0; // To track the original total before discounts

        // Iterate through each item in the cart
        Object.values(cart).forEach(item => {
            const coupon = useCouponCode.find(coupon => coupon.p_id === item.id);
            const coupon_without_pid = useCouponCode.find(coupon => coupon.p_id === '');
            const additional = additionalDiscount.find(discount => discount.id === item.id);

            const itemOriginalPrice = item.current_price * item.qty; // Original price without discounts
            original_total += itemOriginalPrice; // Add to original total

            let priceAfterCoupon = item.current_price; // Start with the original price

            // Apply the coupon first if it exists
            if (coupon) {
                priceAfterCoupon = totalPriceAfterDiscount(item.current_price, coupon.discount);
            } else if (coupon_without_pid) {
                priceAfterCoupon = totalPriceAfterDiscount(item.current_price, coupon_without_pid.discount);
            }

            // Then apply the additional discount if it exists
            let finalPrice = priceAfterCoupon;
            if (additional) {
                finalPrice = totalPriceAfterDiscount(priceAfterCoupon, additional.discount);
            }

            // Add the discounted price * quantity to the total
            new_total += item.qty * finalPrice;
        });

        setOriginalTotal(original_total); // Update the original total state
        setTotal(new_total); // Update the total after discounts
    }, [forceUpdate, cart, useCouponCode, additionalDiscount]);

    const calculateDiscountedPrice = (
        quantity,
        basePrice,
        minOrderQuantity,
        minDiscount,
        maxDiscount,
        minItemDiscount,
        maxItemDiscount
    ) => {
        let appliedDiscount = 0; // Default discount is 0

        // Convert minItemDiscount and maxItemDiscount to arrays of numbers
        const minItemDiscountArray = minItemDiscount.split(',').map(Number);
        const maxItemDiscountArray = maxItemDiscount.split(',').map(Number);

        // If the quantity is equal to minOrderQuantity, set appliedDiscount to 0
        if (quantity === minOrderQuantity) {
            appliedDiscount = 0;
        }
        // Apply minDiscount if the quantity is within the minItemDiscount range
        else if (quantity >= minItemDiscountArray[0] && quantity <= minItemDiscountArray[1]) {
            appliedDiscount = minDiscount;
        }
        // Apply maxDiscount if the quantity is within the maxItemDiscount range
        else if (quantity >= maxItemDiscountArray[0] && quantity <= maxItemDiscountArray[1]) {
            appliedDiscount = maxDiscount;
        }
        // If the quantity is between the two ranges, calculate a proportional discount
        else if (minItemDiscountArray[0] === 0 && minItemDiscountArray[1] === 0 && quantity > minOrderQuantity && quantity < maxItemDiscountArray[0]) {
            const discountRange = maxDiscount - minDiscount;
            const qtyRange = maxItemDiscountArray[0] - minOrderQuantity;
            const qtyBeyondMin = quantity - minOrderQuantity;

            // Calculate the proportional discount
            appliedDiscount = minDiscount + (discountRange * (qtyBeyondMin / qtyRange));
        }
        // If the quantity is between the two ranges, calculate a proportional discount
        else if (quantity > minItemDiscountArray[1] && quantity < maxItemDiscountArray[0]) {
            const discountRange = maxDiscount - minDiscount;
            const qtyRange = maxItemDiscountArray[0] - minItemDiscountArray[1];
            const qtyBeyondMin = quantity - minItemDiscountArray[1];

            // Calculate the proportional discount
            appliedDiscount = minDiscount + (discountRange * (qtyBeyondMin / qtyRange));
        }
        // If quantity exceeds maxItemDiscount range, apply maxDiscount
        else if (quantity > maxItemDiscountArray[1]) {
            appliedDiscount = maxDiscount;
        }

        // Calculate the discounted price per unit
        const discountedPrice = basePrice - (basePrice * (appliedDiscount / 100));

        // Calculate the total price
        const totalPrice = quantity * discountedPrice;

        // If no match is found, return totalPrice = quantity * basePrice;
        if (appliedDiscount === 0) {
            return { totalPrice: quantity * basePrice, appliedDiscount: 0 };
        }

        console.log(appliedDiscount);

        // Return both the total price and the applied discount percentage
        return { totalPrice, appliedDiscount };
    };

    const handleApplyCoupon = async () => {
        // Call API to validate coupon code and get discount amount
        if (couponCode !== '') {

            setLoadingTotal(true);
            try {
                let dataX = null
                if (productID) {
                    dataX = {
                        couponCode: couponCode,
                        product_id: productID
                    }
                } else {
                    dataX = {
                        couponCode: couponCode
                    }
                }

                setError('');
                const response = await fetch(`${process.env.REACT_APP_API}/coupon_validate`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    },
                    body: JSON.stringify(dataX),
                });

                let data = await response.json();
                if (!response.ok) {
                    toast.error(data.message, {
                        position: 'top-right',
                        autoClose: 3000,
                    })
                } else {
                    setShowModal(false);
                    setProductID('');

                    const updateCouponCode = (coupon, item) => {
                        return (coupon.p_id === item.p_id)  // This includes the case where p_id is an empty string
                            ? { id: item.id, code: item.code, discount: item.percentage, p_id: item.p_id }
                            : coupon;
                    };

                    const updatedUsedCoupon = Array.isArray(data)
                        ? data.reduce((acc, item) => {
                            // Check if the coupon with the same p_id (including empty p_id) already exists
                            const exists = acc.some(coupon => coupon.p_id === item.p_id);
                            return exists
                                ? acc.map(coupon => updateCouponCode(coupon, item))  // Replace the matching coupon (even if p_id is '')
                                : [...acc, { id: item.id, code: item.code, discount: item.percentage, p_id: item.p_id }];
                        }, useCouponCode)
                        : useCouponCode.some(coupon => coupon.p_id === data.p_id)
                            ? useCouponCode.map(coupon => updateCouponCode(coupon, data))
                            : [...useCouponCode, { id: data.id, code: data.code, discount: data.percentage, p_id: data.p_id }];

                    setUsedCouponCode(updatedUsedCoupon);

                    setForceUpdate(!forceUpdate);
                    setCouponCode('');
                    // setDiscount(true);
                }
            } catch (error) {
                toast.error(error.message, {
                    position: 'top-right',
                    autoClose: 3000,
                })
            } finally {
                setLoadingTotal(false);
            }

        } else {
            setError(t('coupon_error'));
        }
    };

    const totalPriceAfterDiscount = (total, discount) => {
        const totalPrice = total - (total * (discount / 100));
        return totalPrice;
    }

    const handleCheckout = async () => {
        if (token) {
            const cartArray = Object.values(cart);
            await createOrder(cartArray, useCouponCode, additionalDiscount);
            localStorage.removeItem('cart');
            cartArray.map(item => removeFromCart(item));
            navigate('/order');
        } else {
            navigate('/login');
        }
    };

    const addCommasToNumber = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const handleAddToCart = (product) => {
        const current_pro_quantity = getQuantity(product);

        // Calculate the discount based on the updated quantity
        const newQuantity = current_pro_quantity + 1;
        const returnedData = calculateDiscountedPrice(newQuantity, product.current_price, product.min_order, product.min_discount, product.max_discount, product.item_min_discount, product.item_max_discount);

        if (current_pro_quantity === 0) {
            // First time adding to cart
            addToCart(product);
        } else {
            // Increasing quantity
            increaseQty(product);

            // Update additionalDiscount array, checking if the product already exists
            if (returnedData.appliedDiscount > 0) {
                const updatedAdditionalDiscount = additionalDiscount.some(discount => discount.id === product.id)
                    ? additionalDiscount.map(discount =>
                        discount.id === product.id
                            ? { ...discount, discount: returnedData.appliedDiscount }
                            : discount
                    )
                    : [...additionalDiscount, { id: product.id, discount: returnedData.appliedDiscount }];

                setAdditionalDiscount(updatedAdditionalDiscount);
            }
        }
    };

    const handleAddToCartInput = (product, newQty) => {
        // const current_pro_quantity = getQuantity(product);

        const returnedData = calculateDiscountedPrice(newQty, product.current_price, product.min_order, product.min_discount, product.max_discount, product.item_min_discount, product.item_max_discount);

        // Increasing quantity
        increaseQtyInput(product, newQty);

        // Update additionalDiscount array, checking if the product already exists
        if (returnedData.appliedDiscount > 0) {
            const updatedAdditionalDiscount = additionalDiscount.some(discount => discount.id === product.id)
                ? additionalDiscount.map(discount =>
                    discount.id === product.id
                        ? { ...discount, discount: returnedData.appliedDiscount }
                        : discount
                )
                : [...additionalDiscount, { id: product.id, discount: returnedData.appliedDiscount }];

            setAdditionalDiscount(updatedAdditionalDiscount);
        }
    };

    const handleRemoveFromCart = (product) => {
        const current_pro_quantity = getQuantity(product);

        if (current_pro_quantity <= product.min_order) {
            // Remove product if it's at or below min_order
            removeFromCart(product);

            // Filter out the discount with the matching id
            const updatedAdditionalDiscount = additionalDiscount.filter(discount => discount.id !== product.id);

            // Update the state with the filtered discounts
            setAdditionalDiscount(updatedAdditionalDiscount);
        } else {
            // Decrease the quantity by 1
            decreaseQty(product);

            // Calculate the new discount based on the decreased quantity
            const returnedData = calculateDiscountedPrice(
                current_pro_quantity - 1,
                product.current_price,
                product.min_order,
                product.min_discount,
                product.max_discount,
                product.item_min_discount,
                product.item_max_discount
            );

            // If the discount is greater than 0, update the list; if not, remove the product from the discount list
            const updatedAdditionalDiscount = returnedData.appliedDiscount > 0
                ? additionalDiscount.some(discount => discount.id === product.id)
                    ? additionalDiscount.map(discount =>
                        discount.id === product.id
                            ? { ...discount, discount: returnedData.appliedDiscount } // Update discount if product exists
                            : discount
                    )
                    : [...additionalDiscount, { id: product.id, discount: returnedData.appliedDiscount }] // Add new discount
                : additionalDiscount.filter(discount => discount.id !== product.id); // Remove if discount is 0

            setAdditionalDiscount(updatedAdditionalDiscount);
        }
    };

    const handleRemoveFromCartInput = (product) => {
        // Decrease the quantity by 1
        decreaseQtyInput(product, product.min_order);

        // Calculate the new discount based on the decreased quantity
        const returnedData = calculateDiscountedPrice(
            product.min_order,
            product.current_price,
            product.min_order,
            product.min_discount,
            product.max_discount,
            product.item_min_discount,
            product.item_max_discount
        );

        // If the discount is greater than 0, update the list; if not, remove the product from the discount list
        const updatedAdditionalDiscount = returnedData.appliedDiscount > 0
            ? additionalDiscount.some(discount => discount.id === product.id)
                ? additionalDiscount.map(discount =>
                    discount.id === product.id
                        ? { ...discount, discount: returnedData.appliedDiscount } // Update discount if product exists
                        : discount
                )
                : [...additionalDiscount, { id: product.id, discount: returnedData.appliedDiscount }] // Add new discount
            : additionalDiscount.filter(discount => discount.id !== product.id); // Remove if discount is 0

        setAdditionalDiscount(updatedAdditionalDiscount);

    };

    const handleQuantityChange = (e, curElm) => {
        const newQty = e.target.value.trim() === '' ? '' : parseInt(e.target.value);

        // Update the cart item's quantity if the input is not empty and is a number
        if (newQty === '' || (!isNaN(newQty) && newQty >= 0)) {
            // If input is not empty and greater than the minimum order, handle the cart update
            if (newQty !== '' && newQty > curElm.min_order) {
                if (newQty > curElm.stock) {
                    handleAddToCartInput(curElm, curElm.stock);
                } else {
                    handleAddToCartInput(curElm, newQty);
                }
            } else {
                handleRemoveFromCartInput(curElm);
            }
        }
    };

    return (
        <div className='cart dark:bg-drkmode'>
            {
                Object.keys(cart).length === 0 &&
                <div className='empty_cart'>
                    <h2 className='dark:text-gray-200'>{t('your_shopping_cart_is_empty')}</h2>
                    <Link to='/'><button className='dark:bg-drkmodebrand bg-brand'>{t('shop_now')}</button></Link>
                </div>
            }
            {Object.keys(cart).length > 0 &&
                (
                    <span className='dark:text-gray-200'>{t('coupon_question')}
                        <a
                            className='coupon dark:text-drkmodebrand text-brand'
                            onClick={() => {
                                setShowModal(true)
                            }}>{t('coupon_link')}
                        </a>
                    </span>
                )
            }
            <div className='container'>
                {
                    Object.values(cart).map((curElm) => (
                        <div className='box' key={curElm.id}>
                            <div className='img_box'>
                                <img className='mb-5' src={`${process.env.REACT_APP_API}/${curElm.photos[0]}`} alt='' />
                                {
                                    useCouponCode.find(coupon => coupon.p_id === curElm.id)
                                        ?
                                        (
                                            <></>
                                        )
                                        :
                                        (
                                            curElm.has_coupon === 1 &&
                                            (<span className='dark:text-gray-200'>{t('coupon_question')}
                                                <a
                                                    className='coupon dark:text-drkmodebrand text-brand'
                                                    onClick={() => {
                                                        setProductID(curElm.id)
                                                        setShowModal(true)
                                                    }}>{t('coupon_link')}
                                                </a>
                                            </span>)
                                        )
                                }
                            </div>
                            <div className='detail'>
                                <div className='info'>
                                    <h4 className='dark:text-gray-200'>{curElm.category_name} / {curElm.subcategory_name}</h4>
                                    <h3 className='dark:text-gray-200'>{curElm.name}</h3>
                                    <p className='dark:text-drkmodebrand text-brand'>
                                        {t('price')}: {addCommasToNumber(curElm.current_price)}
                                    </p>
                                    {additionalDiscount.length > 0 || useCouponCode.length > 0 ? (
                                        (() => {
                                            const coupon = useCouponCode.find(coupon => coupon.p_id === curElm.id);
                                            const coupon_without_pid = useCouponCode.find(coupon => coupon.p_id === '');
                                            const additional = additionalDiscount.find(discount => discount.id === curElm.id);
                                            const originalPrice = curElm.current_price * curElm.qty;

                                            if (coupon && additional) {
                                                // Apply coupon discount first, then additional discount on the already discounted price
                                                const priceAfterCoupon = totalPriceAfterDiscount(originalPrice, coupon.discount);
                                                const finalPrice = totalPriceAfterDiscount(priceAfterCoupon, additional.discount);
                                                return (
                                                    <>
                                                        <p className='line-through' style={{ color: 'red' }}>
                                                            {t('total')}: {addCommasToNumber(originalPrice)}
                                                        </p>
                                                        <p className='dark:text-drkmodebrand text-brand'>
                                                            {t('total')}: {addCommasToNumber(finalPrice.toFixed(2))}
                                                        </p>
                                                    </>
                                                );
                                            } else if (coupon_without_pid && additional) {
                                                // Apply coupon discount first, then additional discount on the already discounted price
                                                const priceAfterCoupon = totalPriceAfterDiscount(originalPrice, coupon_without_pid.discount);
                                                const finalPrice = totalPriceAfterDiscount(priceAfterCoupon, additional.discount);
                                                return (
                                                    <>
                                                        <p className='line-through' style={{ color: 'red' }}>
                                                            {t('total')}: {addCommasToNumber(originalPrice)}
                                                        </p>
                                                        <p className='dark:text-drkmodebrand text-brand'>
                                                            {t('total')}: {addCommasToNumber(finalPrice.toFixed(2))}
                                                        </p>
                                                    </>
                                                );
                                            } else if (additional) {
                                                // Only the additional discount is applied
                                                const finalPrice = totalPriceAfterDiscount(originalPrice, additional.discount);
                                                return (
                                                    <>
                                                        <p className='line-through' style={{ color: 'red' }}>
                                                            {t('total')}: {addCommasToNumber(originalPrice)}
                                                        </p>
                                                        <p className='dark:text-drkmodebrand text-brand'>
                                                            {t('total')}: {addCommasToNumber(finalPrice.toFixed(2))}
                                                        </p>
                                                    </>
                                                );
                                            } else if (coupon) {
                                                // Only the coupon discount is applied
                                                const finalPrice = totalPriceAfterDiscount(originalPrice, coupon.discount);
                                                return (
                                                    <>
                                                        <p className='line-through' style={{ color: 'red' }}>
                                                            {t('total')}: {addCommasToNumber(originalPrice)}
                                                        </p>
                                                        <p className='dark:text-drkmodebrand text-brand'>
                                                            {t('total')}: {addCommasToNumber(finalPrice.toFixed(2))}
                                                        </p>
                                                    </>
                                                );
                                            } else if (coupon_without_pid) {
                                                // Only the coupon discount is applied
                                                const finalPrice = totalPriceAfterDiscount(originalPrice, coupon_without_pid.discount);
                                                return (
                                                    <>
                                                        <p className='line-through' style={{ color: 'red' }}>
                                                            {t('total')}: {addCommasToNumber(originalPrice)}
                                                        </p>
                                                        <p className='dark:text-drkmodebrand text-brand'>
                                                            {t('total')}: {addCommasToNumber(finalPrice.toFixed(2))}
                                                        </p>
                                                    </>
                                                );
                                            } else {
                                                // No discount
                                                return (
                                                    <p className='dark:text-drkmodebrand text-brand'>
                                                        {t('total')}: {addCommasToNumber(originalPrice)}
                                                    </p>
                                                );
                                            }
                                        })()
                                    ) : (
                                        // If discount logic is false or not applicable
                                        <p className='dark:text-drkmodebrand text-brand'>
                                            {t('total')}: {addCommasToNumber(curElm.current_price * curElm.qty)}
                                        </p>
                                    )}
                                </div>
                                <div className='quantity'>
                                    <button className='bg-brand dark:bg-drkmodebrand' onClick={() => handleRemoveFromCart(curElm)}>-</button>
                                    <input type='number' className='!text-center dark:bg-drkmode dark:text-gray-200'
                                        min={curElm.min_order}
                                        value={curElm.qty}
                                        onChange={(e) => {
                                            handleQuantityChange(e, curElm)
                                        }} />
                                    {
                                        curElm.stock > getQuantity(curElm) ?
                                            (
                                                <button className='text-white bg-brand dark:bg-drkmodebrand' onClick={() => handleAddToCart(curElm)}>+</button>
                                            )
                                            :
                                            (
                                                <button className='text-white bg-gray-500 dark:bg-drkmodebrand'>+</button>
                                            )
                                    }
                                </div>
                                <div className='icon'>
                                    <AiOutlineClose className='dark:text-drkmodebrand' onClick={() => removeFromCart(curElm)} />
                                </div>
                            </div>
                        </div>

                    ))
                }
            </div>
            {
                Object.keys(cart).length > 0 &&
                <div className='bottom bottom flex justify-between items-center'>
                    <div className='Total'>
                        {
                            loadingTotal ?
                                (
                                    <>
                                        <p className='dark:text-gray-200 ml-3'>Calculating the total .....</p>
                                    </>
                                )
                                :
                                additionalDiscount.length > 0 || useCouponCode.length > 0
                                    ?
                                    (
                                        <>
                                            <h4 style={{ color: 'red', textDecoration: 'line-through' }}>{t('total_price')}: {addCommasToNumber(originalTotal.toFixed(2))}</h4>
                                            <h4 className='text-brand dark:text-drkmodebrand'>{t('total_price')}: {addCommasToNumber(total.toFixed(2))}</h4>
                                        </>
                                    )
                                    :
                                    (
                                        <h4 className='text-brand dark:text-drkmodebrand'>{t('total_price')}: {addCommasToNumber(total.toFixed(2))}</h4>
                                    )
                        }
                    </div>
                    <button className='bg-brand dark:bg-drkmodebrand' onClick={handleCheckout}>{t('checkout')}</button>
                </div>
            }

            {
                showModal &&
                (
                    <div className='modal-overlay' style={{ display: showModal ? 'block' : 'none' }}>
                        <div className='modal dark:bg-drkmode'>
                            <div className='modal-content'>
                                <div className='modal-header'>
                                    <h2 className='dark:text-gray-200'>{t('coupon_title')}</h2>
                                    <AiOutlineClose onClick={() => {
                                        setCouponCode('')
                                        setShowModal(false)
                                    }} className='icon-close dark:text-gray-200' />
                                </div>
                                <div className='modal-body'>
                                    <input
                                        type='text'
                                        className='border dark:border-drkmodebrand border-brand'
                                        placeholder={t('coupon_placeholder')}
                                        value={couponCode}
                                        onChange={(e) => setCouponCode(e.target.value)}
                                    />
                                    {
                                        error && (
                                            <>
                                                <p className="error">{error}</p>
                                            </>
                                        )
                                    }
                                </div>
                                <div className='modal-footer'>
                                    <button onClick={handleApplyCoupon} className='coupon-btn dark:bg-drkmodebrand bg-brand'>{t('coupon_button')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div >
    );
};

export default Cart;
