import React, { useState, useEffect } from 'react';
import './wishlist.css';
import { AiFillHeart, AiFillEye, AiOutlineClose } from 'react-icons/ai';
import { useCart } from './contexts/cartContext'; // Import the custom hook
import { useWishlist } from './contexts/wishlistContext';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import { toast } from 'react-toastify';
import { useAuth } from "./contexts/authContext";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Shop = () => {
    const [showDetail, setShowDetail] = useState(false);
    const [detail, setDetail] = useState([]);
    const { addToCart, removeFromCart, getQuantity, decreaseQty, increaseQty } = useCart();
    const { filteredProducts, forceUpdate, setForceUpdate } = useWishlist();
    const { userID, token } = useAuth()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    function calculatePercentageChange(originalPrice, currentPrice) {
        // Check if the original price is zero to avoid division by zero
        if (originalPrice === 0) {
            throw new Error("Original price cannot be zero.");
        }

        // Calculate the percentage change
        const percentageChange = ((currentPrice - originalPrice) / originalPrice) * 100;

        return (percentageChange).toFixed(2);
    }

    if (!token) {
        navigate('/');  
    }

    // Showing Detail Box
    const detailpage = (product) => {
        const detaildata = [{ product }];
        const productdetail = detaildata[0]['product'];
        setDetail(productdetail);
        setShowDetail(true);
    };

    const closedetail = () => {
        setShowDetail(false);
    };

    const handleAddToCart = (product) => {
        const current_pro_quantity = getQuantity(product)

        if (current_pro_quantity === 0) {
            addToCart(product);
        } else {
            increaseQty(product)
        }
    };

    const handleRemoveFromCart = (product) => {
        const current_pro_quantity = getQuantity(product);

        if (current_pro_quantity <= product.min_order) {
            removeFromCart(product); // Remove product if it's at or below min_order
        } else {
            decreaseQty(product); // Otherwise, just decrease the quantity by 1
        }
    };

    const handleWishlist = async (product) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API}/wishlist/${product.w_id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            });

            if (!response.ok) {
                toast.error('Something went wrong while removing product from wishlist.', {
                    position: 'top-right',
                    autoClose: 3000, // 3 seconds
                })
            } else {
                setForceUpdate(!forceUpdate);
                toast.success('Product removed to wishlist.', {
                    position: 'top-right',
                    autoClose: 3000, // 3 seconds
                })
            }

        } catch (error) {
            toast.error('Something went wrong.', {
                position: 'top-right',
                autoClose: 3000, // 3 seconds
            })
        }
    }

    useEffect(() => {
        setForceUpdate(!forceUpdate);
    }, []);

    return (
        <>
            {showDetail ? (
                <div className="product_detail dark:bg-drkmode">
                    <button className="close_btn dark:text-drkmodebrand" onClick={closedetail}>
                        <AiOutlineClose />
                    </button>
                    <div className="container">
                        <div className="img_box" style={{ position: 'relative', maxWidth: '600px', margin: '0 auto' }}>
                            {detail.price > detail.current_price && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '10px',
                                        right: '10px',
                                        backgroundColor: 'red',
                                        color: 'white',
                                        padding: '5px',
                                        borderRadius: '5px',
                                    }}
                                >
                                    {calculatePercentageChange(detail.price, detail.current_price)}% {t('off')}
                                </div>
                            )}
                            <Swiper
                                spaceBetween={50}
                                slidesPerView={1}
                                pagination={{ clickable: true }}
                                modules={[Pagination, Navigation, Autoplay]}
                                autoplay={{ delay: 3000 }}
                                loop={true}
                            >
                                {detail.photos && detail.photos.length > 0
                                    ? detail.photos.map((photo, index) => (
                                        <SwiperSlide key={index} className='flex justify-center'>
                                            <img
                                                src={`${process.env.REACT_APP_API}/${photo}`}
                                                alt={`Slide ${index + 1}`}
                                                style={{
                                                    width: '100%',
                                                    height: 'auto',
                                                    maxHeight: '400px',
                                                    objectFit: 'contain',
                                                }}
                                            />
                                        </SwiperSlide>
                                    ))
                                    : null}
                            </Swiper>
                        </div>
                        <div className="info overflow-auto max-h-[400px]">
                            <h4 className="dark:text-gray-200">
                                / {detail.category_name} / {detail.subcategory_name}
                            </h4>
                            <h2 className="dark:text-gray-200">{detail.name}</h2>
                            <p className="dark:text-gray-200">{detail.description}</p>
                            {detail.price === detail.current_price ? (
                                <h3 className="font-bold text-brand dark:text-drkmodebrand">
                                    {t('etb')} {detail.current_price}
                                </h3>
                            ) : detail.price > detail.current_price ? (
                                <>
                                    <h3
                                        className="font-bold"
                                        style={{ textDecoration: 'line-through', color: 'red' }}
                                    >
                                        {t('etb')} {detail.price}
                                    </h3>
                                    <h3 className="font-bold text-brand dark:text-drkmodebrand">
                                        {t('etb')} {detail.current_price}
                                    </h3>
                                </>
                            ) : (
                                <h3 className="font-bold text-brand dark:text-drkmodebrand">
                                    {t('etb')} {detail.current_price}
                                </h3>
                            )}
                            <p className="dark:text-gray-200">{t('minimum_order')}: {detail.min_order}</p>
                            <p className="dark:text-gray-200">{t('available')}: {detail.stock}</p>
                            {getQuantity(detail) > 0 ? (
                                <div className="quantity_controls">
                                    <button
                                        className="bg-brand text-white dark:bg-drkmodebrand"
                                        onClick={() => handleRemoveFromCart(detail)}
                                    >
                                        -
                                    </button>
                                    <span className="dark:text-gray-200">{getQuantity(detail)}</span>
                                    <button
                                        className="bg-brand text-white dark:bg-drkmodebrand"
                                        onClick={() => handleAddToCart(detail)}
                                    >
                                        +
                                    </button>
                                </div>
                            ) : (
                                <button
                                    className="bg-brand text-white dark:bg-drkmodebrand"
                                    onClick={() => handleAddToCart(detail)}
                                >
                                    {t('add_to_cart')}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            ) : null}

            <div className='shopx dark:bg-drkmode'>
                <div className='container'>
                    <div className='right_box'>
                        <div className='product_box'>
                            <h2 className='text-center dark:text-gray-200'>{t('all_wishlist')}</h2>
                            <div className='add_box'>
                                {/* Add your add box content here */}
                            </div>
                            <div className={`${filteredProducts.length > 0 ? 'product_container' : 'has-filter'}`}>
                                <div className={`grid ${filteredProducts.length > 0 ? 'grid-cols-2 md:grid-cols-3' : 'grid-cols-1'}`}>
                                    {
                                        filteredProducts.length > 0
                                            ? (
                                                filteredProducts.map((curElm) => (
                                                    <div className="box" key={curElm.id}>
                                                        <div className="img_box">
                                                            {/* Display percentage decrease if price has decreased */}
                                                            {curElm.price > curElm.current_price && (
                                                                <div style={{ position: 'absolute', top: '10px', right: '10px', backgroundColor: 'red', color: 'white', padding: '5px', borderRadius: '5px' }}>
                                                                    {calculatePercentageChange(curElm.price, curElm.current_price)}% {t('off')}
                                                                </div>
                                                            )}
                                                            <img src={`${process.env.REACT_APP_API}/${curElm.photos[0]}`} alt='' />
                                                            <div className="icon">
                                                                <li onClick={() => detailpage(curElm)} className='dark:hover:bg-drkmodebrand hover:bg-brand bg-white dark:bg-white'>
                                                                <AiFillEye className='dark:hover:text-gray-200 hover:text-gray-200' />
                                                                </li>
                                                                <li className='remove' onClick={() => handleWishlist(curElm)}>
                                                                    <AiFillHeart />
                                                                </li>
                                                            </div>
                                                        </div>
                                                        <div className="detail">
                                                            <h3 className='dark:text-gray-200'>{curElm.name}</h3>
                                                            {
                                                                curElm.price === curElm.current_price
                                                                    ?
                                                                    (
                                                                        <p className='text-brand dark:text-drkmodebrand'>{t('etb')} {curElm.price}</p>
                                                                    )
                                                                    :
                                                                    (
                                                                        curElm.price > curElm.current_price
                                                                            ?
                                                                            (
                                                                                <>
                                                                                    <p style={{ textDecoration: 'line-through', color: 'red' }}>{t('etb')} {curElm.price}</p>
                                                                                    <p className='dark:text-drkmodebrand text-brand'>{t('etb')} {curElm.current_price}</p>
                                                                                </>
                                                                            )
                                                                            :
                                                                            (
                                                                                <p className='dark:text-drkmodebrand text-brand'>{t('etb')} {curElm.current_price}</p>
                                                                            )
                                                                    )
                                                            }

                                                            <p className='stock dark:text-gray-200'>{t('available')}: {curElm.stock}</p>
                                                            {/* <p className='min_order'>{t('minimum_order')}: {curElm.min_order}</p> */}
                                                            {getQuantity(curElm) > 0 ? (
                                                                <div className="quantity_controls">
                                                                    <button className='dark:bg-drkmodebrand dark:text-gray-200 bg-brand text-white' onClick={() => handleRemoveFromCart(curElm)}>-</button>
                                                                    <span className='dark:text-gray-200'>{getQuantity(curElm)}</span>
                                                                    <button className='dark:bg-drkmodebrand dark:text-gray-200 bg-brand text-white' onClick={() => handleAddToCart(curElm)}>+</button>
                                                                </div>
                                                            ) : (
                                                                <button className='dark:bg-drkmodebrand dark:text-gray-200 text-white bg-brand' onClick={() => handleAddToCart(curElm)}>{t('add_to_cart')}</button>
                                                            )}
                                                        </div>
                                                    </div>

                                                ))
                                            )
                                            : (
                                                <p className="text-center">{t('no_wishlist_product_found')}</p>
                                            )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};

export default Shop;
