import React, { createContext, useState, useEffect, useContext, useReducer } from 'react';
import wishlistReducer from './wishlistReducer';
import { toast } from 'react-toastify';

// Create the context
const WishlistContext = createContext();

// Provider component
export const WishlistProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [state, dispatch] = useReducer(wishlistReducer, { filteredProducts: [] });
  const token = localStorage.getItem('token');
  const [forceUpdate, setForceUpdate] = useState(false);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}/wishlist`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          }
        });

        if (!response.ok) {
          toast.error('Something went wrong while fetching wishlisted products data.', {
            position: 'top-right',
            autoClose: 3000, // 3 seconds
          });
          return; // Exit if the response is not OK
        }

        const data = await response.json();

        dispatch({ type: 'SET_FILTERED_PRODUCTS', payload: data });
      } catch (e) {
        setError('Failed to fetch products.');
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      fetchProducts();
    } else {
      setLoading(false);
      setError('No authentication token found.');
    }
  }, [token, forceUpdate]); // Add forceUpdate to dependencies to re-fetch when it changes

  return (
    <WishlistContext.Provider value={{ ...state, loading, error, forceUpdate, setForceUpdate }}>
      {children}
    </WishlistContext.Provider>
  );
};

// Custom hook to use the WishlistContext
export const useWishlist = () => {
  return useContext(WishlistContext);
};
