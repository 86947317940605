import { useEffect, useState } from 'react';
import { AiOutlineMoon, AiOutlineSun } from 'react-icons/ai';

const ThemeToggle = () => {
    const [theme, setTheme] = useState('light');

    useEffect(() => {
        const savedTheme = localStorage.getItem('theme') || 'light';
        setTheme(savedTheme);
        document.documentElement.classList.add(savedTheme);
    }, []);

    const toggleTheme = () => {
        const newTheme = theme === 'light' ? 'dark' : 'light';
        setTheme(newTheme);
        document.documentElement.classList.remove(theme);
        document.documentElement.classList.add(newTheme);
        localStorage.setItem('theme', newTheme);
    };

    return (
        <a
            onClick={toggleTheme}
            className="p-2 dark:bg-drkmode text-gray-800 dark:text-gray-200"
        >
            {theme === 'light' ? <AiOutlineMoon className='text-2xl text-brand'/> : <AiOutlineSun className='text-2xl dark:text-gray-200 text-brand'/>}
        </a>
    );
};

export default ThemeToggle;
