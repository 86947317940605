import React from 'react'
import { Routes, Route } from 'react-router';
import Shop from './shop';
import Cart from './cart';
import Contact from './contact';
import Order from './order';
import Account from './account'
import Registration from './registration'
import Login from './login'
import Wishlist from './wishlist'

const Rout = () => {
  return (
    <>
      <Routes>
        <Route path='/cart' element={<Cart />} />
        <Route path='/' element={<Shop />} />
        <Route path='/order' element={<Order />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/account' element={<Account />} />
        <Route path='/wishlist' element={<Wishlist />} />
        <Route path='/registration' element={<Registration />} />
        <Route path='/login' element={<Login />} />
      </Routes>
    </>
  )
}

export default Rout