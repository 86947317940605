import React, { createContext, useState, useEffect, useContext, useReducer } from 'react';
import productReducer from './productReducer';
import { toast } from 'react-toastify';

// Create the context
const ProductContext = createContext();

// Provider component
export const ProductProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [reload, setReload] = useState(false);
  const [state, dispatch] = useReducer(productReducer, { products: [], filteredProducts: [], advertisements: [] });
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${process.env.REACT_APP_API}/product_no_auth`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        });

        if (!response.ok) {
          toast.error('Something went wrong while fetching products data.', {
            position: 'top-right',
            autoClose: 3000, // 3 seconds
          });
        }

        const data = await response.json();

        dispatch({ type: 'SET_PRODUCTS', payload: data });
      } catch (e) {
        setError('Failed to fetch products.');
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [reload])

  useEffect(() => {
    const fetchAdvertisements = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}/advertisement`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        });

        if (!response.ok) {
          toast.error('Something went wrong while fetching advertisements data.', {
            position: 'top-right',
            autoClose: 3000, // 3 seconds
          });
        }

        const data = await response.json();

        dispatch({ type: 'SET_ADVERTISEMENTS', payload: data });
      } catch (e) {
        setError('Failed to fetch advertisements.');
      } finally {
        setLoading(false);
      }
    };

    fetchAdvertisements();
  }, [reload]);

  const setProducts = (products) => {
    dispatch({ type: 'SET_PRODUCTS', payload: products });
  };

  const setSearching = (search) => {
    setIsSearching(search);
  };

  const filterProductsByCategory = (categoryName) => {
    dispatch({ type: 'FILTER_BY_CATEGORY', payload: categoryName });
  };
  const searchProducts = (searchTerm) => {
    const filtered = state.products.filter(product =>
      product.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    dispatch({ type: 'SET_FILTERED_PRODUCTS', payload: filtered });
  };
  return (
    <ProductContext.Provider value={{ ...state, setProducts, loading, setLoading, reload, setReload, error, filterProductsByCategory, searchProducts, isSearching, setSearching }}>
      {children}
    </ProductContext.Provider>
  );
};

// Custom hook to use the ProductContext
export const useProducts = () => {
  return useContext(ProductContext);
};
